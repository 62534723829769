<template>
  <div class="stencil">
    <!-- <img :src="require('@/assets/images/banner03.png')" alt="" class="banner" /> -->
    <div class="title_text">
      <img :src="require('@/assets/bgc6.png')" alt="" class="banner" />

      <span class="text"> 闪亮金句 </span>

      <!-- 搜索 -->
      <div class="search_box">
        <input
          class="inp"
          type="text"
          placeholder="请输入内容搜索"
          v-model="searchValue"
          @keyup.enter="search"
        />
        <div class="search_btn" @click="search()">
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>

        <div class="tuijian">
          <div class="tuijian_text">为你推荐:</div>

          <div class="list">
            <div
              class="list_item"
              @click="
                page = 1;
                pageUuid = '';
                getBankList(item.labelId);
                getMuBanList(item.labelId);
                sidebarTtemId = item.labelId;
              "
              v-for="item in tuijianList"
              :key="item.nodeMd5"
            >
              {{ item.conName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 侧边菜单栏 -->
    <div class="box">
      <div class="left leftFlex">
        <!-- 占位 -->

        <div class="perch"></div>
        <!-- 遮罩层 -->
        <div
          class="mask_layer"
          v-show="ChildrenInfoShow"
          @click="ChildrenInfoShow = false"
        ></div>
        <!-- 4级侧边栏 -->
        <div class="sidebar_box" :class="ChildrenInfoShow ? 'disflex' : ''">
          <div
            class="an_btn"
            :class="ChildrenInfoShow ? 'spin' : ''"
            v-show="ChildrenInfo.list.length != 0"
            @click="
              () => {
                ChildrenInfoShow = !ChildrenInfoShow;

                if (ChildrenInfoShow) {
                  if (ChildrenInfo.list.length != 0) {
                    ChildrenInfo.isShow = true;
                  } else if (ChildrenInfo1.list.length != 0) {
                    ChildrenInfo1.isShow = true;
                  } else if (ChildrenInfo2.list.length != 0) {
                    ChildrenInfo2.isShow = true;
                  }
                }
              }
            "
          >
            <i class="el-icon-arrow-right"></i>
          </div>

          <!-- 第一级 -->
          <div class="sidebar">
            <el-menu ref="elMenu" unique-opened :collapse-transition="false">
              <el-menu-item index="-2" @click="handleAllClick">
                全部
              </el-menu-item>
              <el-menu-item index="-1" @click="handleSCClick">
                收藏金句
              </el-menu-item>

              <el-submenu
                :index="item.labelId"
                v-for="(item, index) in bankList"
                @click.native.stop="
                  () => {
                    page = 1;
                    $refs['elMenu'].activeIndex = null;
                    ChildrenInfoShow = false;
                    stair = item.labelId;
                    ChildrenInfo.isShow = false;
                    sidebarTtemId = item.labelId;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                  sidebarTtemId == item.labelId ? 'pitchOn' : '',
                ]"
                :key="item.id"
                :title="item.conName.length > 9 ? item.conName : ''"
              >
                <template slot="title">
                  {{ item.conName }}
                  <el-tooltip
                    effect="light"
                    :content="item.remark"
                    placement="right"
                    popper-class="custom-popper"
                  >
                    <img
                      v-if="item.isVip != 1"
                      class="vipIcon"
                      :src="
                        item.isVip == 2
                          ? require('assets/images/headers/vip.png')
                          : item.isVip == 3
                          ? require('assets/images/headers/svip.png')
                          : item.isVip == 4
                          ? require('assets/images/ban.png')
                          : ''
                      "
                      @click.stop="memberRenewal(item)"
                      alt=""
                  /></el-tooltip>
                  <img
                    v-if="item.labelTip != 0 && item.labelTip != null"
                    :src="
                      item.labelTip == 1
                        ? require('assets/images/hot1.png')
                        : item.labelTip == 2
                        ? require('assets/images/j.png')
                        : require('assets/images/new1.png')
                    "
                    alt=""
                  />
                </template>
                <el-menu-item
                  :index="index + '-' + index1"
                  v-for="(item1, index1) in item.children"
                  :key="item1.id"
                  :title="item1.conName.length > 8 ? item1.conName : ''"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  @click.native.stop="
                    () => {
                      parentName = item1.conName;

                      page = 1;

                      if (ChildrenInfo.list.length != 0) {
                        ChildrenInfo.isShow = true;
                      }
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getChildrenList(item1, 1);
                    }
                  "
                >
                  <!-- <img class="img1" :src="$store.state.onlineBasePath + item1.iconImg" alt="" /> -->
                  {{ item1.conName
                  }}<img
                    v-if="item1.checked == 2"
                    class="img2"
                    :src="require('assets/images/gouwuche1.png')"
                    @click="shoppCar(item1.productId)"
                    alt=""
                  />
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第二级 -->
          <div
            class="sidebar"
            :class="ChildrenInfoShow ? '' : 'absolute'"
            v-show="ChildrenInfo.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              unique-opened
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo.isShow = false;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo.list"
                @click.native="
                  () => {
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
                :title="item.conName.length > 7 ? item.conName : ''"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getChildrenList(item1, 3);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第三级 -->
          <div
            class="sidebar"
            :class="ChildrenInfoShow ? '' : 'absolute'"
            v-show="ChildrenInfo1.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              unique-opened
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo1.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo1.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                @click.native="
                  () => {
                    ChildrenInfo2.isShow = false;
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getChildrenList(item1, 4);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第四级 -->
          <div
            class="sidebar"
            :class="ChildrenInfoShow ? '' : 'absolute'"
            v-show="ChildrenInfo2.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              unique-opened
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo2.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo2.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                @click.native="
                  () => {
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      getChildrenList(item1, 5);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>

        <!-- 主体区域 -->
        <div class="main" id="main">
          <div class="header_box"></div>

          <!-- 金句列表 -->
          <div class="stencil_list">
            <GoldenSentenceDilets
              @pageChange="pageChange"
              @sortClick="sortClick"
              @collectListClick="collectListClick"
              :isPage="isPage"
              :listData="listData"
              :showPage="showPage"
              :labelId="labelId"
              :stair="stair"
              :noText="noText"
            />
          </div>
        </div>
      </div>
      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>

    <!-- 购买金句库弹框 -->
    <el-dialog title="选择购买" :visible.sync="buyDialog" class="buyDialog">
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in ChooseBuy"
          :key="index"
          :class="buyTypeI == index ? 'blues' : ''"
          @click="handleclick(index)"
          v-show="index != 1"
        >
          <div class="title">
            <div class="name">{{ item.title }}</div>

            <div class="price">{{ item.price }} ¥</div>
          </div>

          <div class="introduce">
            {{ item.text }}
          </div>

          <div class="icon" v-show="buyTypeI == index">
            <img :src="require('assets/images/opt_for.png')" alt="" />
          </div>
        </div>
      </div>

      <div class="btn">
        <div class="start_btn">
          <el-button type="text" @click="purchase(ChooseBuy[buyTypeI])">
            购买
          </el-button>
          <div class="bian"></div>
          <el-button type="text" @click="buyDialog = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getProductListGroup,
  gettemplateAndSayingLibrary,
  getlibrarylist,
} from "api/dropDownList";
import GoldenSentenceDilets from "./goldenSentenceDilets.vue";
import recommend from "components/recommend/recommend.vue";
import commonFooter from "components/footer/commonFooter.vue";
import { getToken } from "@/utils/auth";
import qs from "qs";
import {
  oftenWordSearch,
  goldensentenceCollectList,
} from "api/goldenSentenceIndex.js";
import {
  findSelectedLib,
  findOftenLibraryType,
  findOftenWord,
  addTemplateCollect,
  addTemplateHot,
  logAdd,
} from "api/search";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    GoldenSentenceDilets,
    recommend,
    commonFooter,
  },
  name: "stencil",
  data() {
    //这里存放数据
    return {
      isMark: false,
      stair: "JJhexjj00001",
      showPage: true,
      currentClickItemVip: null,
      currentLabelId: "",
      isAn: false,
      openList: [],
      ChildrenInfoShow: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      noText: "更新中",
      pageUuid: "",
      parentName: "",
      // 是否购买
      checked: 0,
      // 选中购买类型
      buyTypeI: 0,
      // 购买选择数据
      ChooseBuy: [
        {
          id: 2,
          price: "98",
          title: "写手智能科技VIP/SVIP会员",
          text: "开通会员全部金句可免费浏览，更多权益点击购买了解详情。",
        },
      ],
      // 购买弹框
      buyDialog: false,
      sidebarTtemId: -1,
      // 库列表
      bankList: [],
      // 收藏
      collect: [],
      // 需要展开的数组
      openeds: [],
      // 搜索关键字
      searchValue: "",

      libraryId: "",

      // 模板列表
      listData: [],
      // 页码
      page: 1,
      busy: false,
      end: false,
      // 空
      isKong: false,
      islLine: false,
      isshow: false,
      isPage: false,
      // 左侧点击数据
      htmlText: {},
      isTheme_box: false,
      sortType: 1,
      sortVal: "desc",
      total: 0,
      // 推挤
      tuijianList: [
        {
          conName: "核心金句",
          labelId: "JJhexjj00001",
        },
        {
          conName: "公文写作",
          labelId: "JJgongwxz00001",
        },
        {
          conName: "唐诗经典",
          labelId: "JJtangsjd00001",
        },
        {
          conName: "元曲经典",
          labelId: "JJyuanqjd00001",
        },
        {
          conName: "现当代小说",
          labelId: "JJxiaosjd00001",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},

  //方法集合
  methods: {
    collectListClick() {
      if (this.isMark) {
        if (this.listData.length == 1 && this.page != 1) {
          this.page--;
        }
        this.getcollectList();
      }
    },
    async getcollectList() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      const params = {
        page: this.page,
        limit: 10,
      };

      const res = await goldensentenceCollectList(params);

      console.log(res);
      if (res.data.data != null) {
        this.listData = res.data.data;
        this.showPage = false;
        this.total = res.data.total;
      } else {
        this.listData = [];
        this.showPage = true;
        this.total = 0;
      }
    },

    sortClick(val) {
      this.sortType = val.sortType;
      this.sortVal = val.sortVal;
      this.page = 1;
      this.pageUuid = "";
      this.getMuBanList(this.stair);
    },
    pageChange(val, isFlag) {
      // console.log(this.currentClickItemVip);
      if (isFlag == 1) {
        if (this.currentClickItemVip && this.currentClickItemVip.isVip != 1) {
          this.memberRenewal(this.currentClickItemVip);
          return;
        }
        this.page = val;
        this.getMuBanList();

        this.$nextTick(() => {
          let PageId = document.querySelector("#main");

          PageId.scrollTop = 0;
        });
      } else {
        this.page = val;
        this.getcollectList();
      }
    },
    // 购买
    purchase(val) {
      if (this.buyTypeI == 0) {
        let productId = this.htmlText.productId;
        try {
          let TokenObj = getToken();
          let access_token = "";
          if (TokenObj) {
            TokenObj = JSON.parse(TokenObj);
            access_token = `${TokenObj.access_token}`;
          }
          // eslint-disable-next-line
          OpenBrowser(
            `https://www.writemall.com/details/${productId}?token=${access_token}`
          );

          // window.location.href = ` http://192.168.2.246:8083/details/${productId}?token=${access_token}`
        } catch (e) {
          console.log(e);
        }
        window.isifHaveBuyDialog();
      }
      if (this.buyTypeI == 1) {
        try {
          let TokenObj = getToken();
          let access_token = "";
          if (TokenObj) {
            TokenObj = JSON.parse(TokenObj);
            access_token = `${TokenObj.access_token}`;
          }
          // eslint-disable-next-line
          OpenBrowser(
            `https://www.writemall.com/details/1?token=${access_token}`
          );
        } catch (e) {
          console.log(e);
        }
        window.isifHaveBuyDialog();
      }
    },

    handleclick(i) {
      this.buyTypeI = i;
    },
    // 购物车跳转
    shoppCar(id) {
      try {
        let TokenObj = getToken();
        let access_token = "";
        if (TokenObj) {
          TokenObj = JSON.parse(TokenObj);
          access_token = `${TokenObj.access_token}`;
        } else {
          access_token = process.env.VUE_APP_DEFAULT_TOKEN;
        }
        // eslint-disable-next-line
        OpenBrowser(
          `https://www.writemall.com/details/${id}?token=${access_token}`
        );
        // window.location.href = `http://localhost:8081/details/${id}?token=${access_token}`
      } catch (error) {
        console.log(error);
      }
      window.isifHaveBuyDialog();
    },

    // 搜索
    search() {
      this.page = 1;
      this.pageUuid = "";
      this.getMuBanList(this.labelId);
    },
    handleAllClick() {
      this.$refs["elMenu"].close(this.stair);
      this.pageUuid = "";
      this.page = 1;
      this.isMark = false;
      this.noText = "更新中";
      this.sidebarTtemId = -1;
      this.getMuBanList();
      this.getBankList("");
      this.labelId = "";
      this.stair = "JJhexjj00001";
      this.ChildrenInfoShow = false;
      this.ChildrenInfo.isShow = false;
      this.ChildrenInfo1.isShow = false;
      this.ChildrenInfo2.isShow = false;
    },
    handleSCClick() {
      this.$refs["elMenu"].close(this.stair);
      this.pageUuid = "";
      this.page = 1;
      this.isMark = true;
      this.noText = "暂无收藏";
      this.stair = "JJhexjj00001";
      this.sidebarTtemId = -1;
      this.getcollectList();
      this.ChildrenInfoShow = false;
      this.ChildrenInfo.isShow = false;
      this.ChildrenInfo1.isShow = false;
      this.ChildrenInfo2.isShow = false;
    },
    async getChildrenList(item, i) {
      // console.log(item);
      this.isMark = false;
      this.labelId = item.labelId;
      if (item.parentId == 2) {
        this.currentClickItemVip = item;

        this.remark = item.remark;
      }
      this.currentLabelId = item.labelId;

      this.pageUuid = "";
      this.searchValue = "";
      // this.labelId = item.labelId;

      this.getMuBanList(item.labelId);
      const data = {
        labelId: item.labelId,
        parentId: 2,
      };

      if (i == 1) {
        this.ChildrenInfo.isShow = true;
        if (this.ChildrenInfo.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo1.isShow = false;
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo1.list = [];
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo.parentName = item.conName;
        this.ChildrenInfo.labelId = item.labelId;
      } else if (i == 3) {
        this.ChildrenInfo1.isShow = true;
        if (this.ChildrenInfo1.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo1.parentName = item.conName;
        this.ChildrenInfo1.labelId = item.labelId;
      } else if (i == 4) {
        this.ChildrenInfo2.isShow = true;
        if (this.ChildrenInfo2.labelId == item.labelId) {
          return;
        }

        this.ChildrenInfo2.parentName = item.conName;
        this.ChildrenInfo2.labelId = item.labelId;
      }
      if (i == 2) {
        if (item.children.length != 0) {
          return;
        }
      }

      const res = await getlibrarylist(qs.stringify(data));

      if (i == 1) {
        this.ChildrenInfo.list = res.data;
      } else if (i == 3) {
        this.ChildrenInfo1.list = res.data;
      } else if (i == 4) {
        this.ChildrenInfo2.list = res.data;
      }

      if (i == 2) {
        this.$set(item, "children", res.data);
      }
    },

    // 获取侧边栏
    async getBankList(labelId) {
      const data = {
        labelId,
        parentId: 2,
      };
      const res = await getlibrarylist(qs.stringify(data));
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.bankList = res.data;
      this.labelId = res.data[0].labelId;
      this.remark = res.data[0].remark;
    },

    // 查询常用语句
    async getMuBanList(labelId) {
      // this.labelId = labelId;
      this.showPage = true;
      this.isKong = false;
      const params = {
        page: this.page,
        key: this.searchValue,
        pageUuid: this.pageUuid,
        labelId: labelId,
        sortType: this.sortType == 1 ? "time_sort" : "hot",
        sort: this.sortVal,
      };

      const res = await oftenWordSearch(params);
      if (res.code != 200) {
        this.end = true;
        this.$message({
          message: res.message,
        });
        return;
      }
      this.total = res.data.totalNum;
      this.pageUuid = res.data.page_uuid;
      this.listData = res.data.data;
    },
    memberRenewal(item, event) {
      if (item.isVip == 4) {
        return;
      }
      this.$store.commit("setLockClickText", item);
      this.$bus.$emit("buyTipsLock");
    },
    // 收藏
    async collectionClick(val) {
      let data = {
        templateId: val.id,
      };
      const res = await addTemplateCollect(data);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }
      this.$message({
        type: "success",
        message: val.collectStatus == 1 ? "取消收藏！" : "收藏成功！",
        duration: "1000",
      });
      val.collectStatus = val.collectStatus == 1 ? 0 : 1;
      this.$forceUpdate();
    },

    // 添加模板下载日志
    async Addlog(arr) {
      const res = await logAdd(arr);
    },

    // 热度+1
    async addHot(id) {
      let formData = new FormData();
      formData.append("tempId", id);
      const res = await addTemplateHot(formData);
    },

    // 模板详情
    stencilClick(item) {
      let arr = [];
      arr.push({
        tempId: item.id,
      });
      this.$router.push({
        name: "stencilDetails",
        params: {
          id: item.id,
        },
      });
      this.addHot(item.id);
      this.Addlog(arr);
    },

    // 侧边栏click
    onMenuClick(val) {
      this.htmlText = val;
      let obj = {
        id: 1,
        price: "0",
        title: val.conName,
        text: "购买即可浏览使用此库内全部金句。",
      };
      if (this.ChooseBuy.length < 2) {
        this.ChooseBuy = [obj, ...this.ChooseBuy];
      } else {
        this.ChooseBuy.shift();
        this.ChooseBuy = [obj, ...this.ChooseBuy];
      }
      this.checked = val.checked;
      this.themeId = val.id;
      this.libraryId = val.id;

      this.page = 1;
      this.listData = [];

      this.searchValue = "";
    },

    // 无限滚动
    loadMore() {
      this.busy = true;
      if (this.end == false) {
        this.page++;
        this.getMuBanList();
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (!this.$route.query?.labelId) {
      this.getBankList("");
    }

    this.getMuBanList("");
    let obj = {
      id: 1,
      price: "0",
      title: "金句库",
      text: "购买即可浏览使用此库内全部金句。",
    };
    this.ChooseBuy = [obj, ...this.ChooseBuy];
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        if (val.query?.labelId) {
          this.page = 1;
          this.pageUuid = "";
          this.sidebarTtemId = this.$route.query.labelId;
          this.getMuBanList(this.sidebarTtemId);
          this.getBankList(this.sidebarTtemId);
        } else {
          this.$refs.elMenu.activeIndex = "-2";
        }
      },
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.$route.query?.labelId) {
      this.sidebarTtemId = this.$route.query.labelId;
      this.getMuBanList(this.sidebarTtemId);
      this.getBankList(this.sidebarTtemId);
    } else {
      this.$refs.elMenu.activeIndex = "-2";
    }
    if (this.$route.params?.searchValue) {
      this.searchValue = this.$route.params?.searchValue;
      this.getMuBanList("");
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.commonFooter_box {
  margin-top: 20px;
  width: 100%;
}
.pitchOn {
  ::v-deep .el-submenu__title {
    box-sizing: border-box;
    border-right: #ff6900 4px solid;
  }
}
.blue {
  color: #ff6900;
}
.absolute {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0px;
}
.stencil {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;

  .title_text {
    position: absolute;
    width: 100%;
    top: -70px;
    left: 0;
    z-index: 0;
    .text {
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-weight: bold;
      font-size: 34px;
    }

    // 搜索
    .search_box {
      box-sizing: border-box;
      position: absolute;
      top: 130px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      display: flex;
      align-items: center;
      border-radius: 3px;
      width: 600px;
      margin: 0 auto;
      .inp {
        height: 26px;
        border-left: 1px solid #e4e4e4;
        padding-left: 20px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-gray;
        flex: 1;
      }
       /* prettier-ignore */
      .search_btn {
        height: 40Px;
        padding: 0 10px;
        background: #ff6900;
        @include flex-center();
        // border-radius: 0 5px 5px 0;
        cursor: pointer;
        > img:first-child {
          width: 52px;
          height: 22px;
          margin: 0 10px;
        }
      }

      .tuijian {
        width: 130%;
        position: absolute;
        bottom: -35px;
        display: flex;
        align-items: center;
        color: #fff;
        margin-top: 10px;
        font-size: 14px;

        .tuijian_text {
          margin-right: 10px;
        }

        .list {
          display: flex;
          align-items: center;

          .list_item {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.2);
            padding: 5px;
            margin-right: 10px;
          }
        }
      }
    }

    .banner {
      width: 100%;
    }
  }

  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 140px auto 0;
    position: relative;

    .left {
      position: relative;
      width: 936px;
      margin-right: 20px;
      display: flex;
      background-color: #fff;
    }

    .right {
      background-color: #fff;
      flex: 1;
    }
  }

  .perch {
    min-width: 210px;
    height: calc(100vh - 51px);
  }

  .disflex {
    display: flex;
  }

  .sidebar_box {
    position: absolute;
    left: 0;
    z-index: 2001;
    background-color: #fff;

    .an_btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      z-index: 10000;
      background-color: #3683f263;
      color: #fff;
      transform: translate(-50%);
      right: -40px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
    }

    .spin {
      transform: translate(-50%) rotate(180deg);
    }
  }
  // 侧边菜单栏
  .sidebar {
    width: 210px;
    height: calc(100vh - 51px);
    overflow-y: auto;
    // border-right: solid 1px #e6e6e6;
    .el-menu {
      border-right: 0px;

      .isSubsetClass {
        ::v-deep .el-submenu__title {
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }
      .charNumClass {
        ::v-deep .el-submenu__title {
          cursor: not-allowed;
          color: #ccc;
        }

        cursor: not-allowed;
        color: #ccc;
      }

      .el-menu-item {
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        -webkit-transition: none;
        transition: none;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        align-items: center;
        white-space: nowrap;

        .el-icon-arrow-left {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0px;
          font-size: 14px;
        }
        &:hover {
          color: #ff6900;
          border-right: #ff6900 4px solid;
        }
      }
      .el-menu-item.is-active {
        border-right: #ff6900 4px solid;
        background-color: #ffbf6914;
      }
    }
    ::v-deep .el-submenu {
      .el-submenu__title {
        display: flex;
        -webkit-transition: none;
        transition: none;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        align-items: center;
        white-space: nowrap;
        position: relative;
        // display: flex;
        align-items: center;

        height: 25px;
        line-height: 25px;
        font-size: 14px;
        .vipIcon {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 40px;
          margin-left: 0px;
        }
        img {
          width: 13px;

          margin-left: 5px;
        }
        &:hover {
          color: #ff6900;
        }
      }
      .el-menu {
        .el-menu-item {
          &:hover {
            color: #ff6900;
            border-right: #ff6900 4px solid;
          }
          min-width: 190px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
          -webkit-transition: none;
          transition: none;
        }
        .el-menu-item.is-active {
          border-right: #ff6900 4px solid;
          background-color: #ffbf6914;
        }
        .is-active {
          border-right: #ff6900 4px solid;
        }
      }
    }

    .img1 {
      width: 20px;
      margin-right: 10px;
    }
    .img2 {
      width: 20px;
      margin-left: 10px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

  // 主体区域
  .main {
    flex: 1;
    padding: 20px;

    // 主题
    .theme_box {
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      // padding: 20px 0 0;
      z-index: 10000;
      width: 239px;
      height: calc(100vh - 51px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      .title {
        font-weight: bold;
      }
      .theme_lists {
        // border-right: solid 1px #e6e6e6;
        box-sizing: border-box;
      }
      .theme_list {
        font-size: 14px;
        .theme_item {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;
          height: 25px;
          line-height: 25px;
          padding: 0 0 0 20px;
          &:hover {
            color: #ff6900;
            border-right: #ff6900 4px solid;
            background-color: #ffbf6914;
          }
        }

        .all {
          cursor: pointer;
          position: absolute;
          bottom: 10px;
          right: 5px;
        }

        .selected {
          color: #ff6900;
          background-color: #ffbf6914;
          border-right: #ff6900 4px solid;
        }
      }
    }
  }
  // 购买弹窗
  .buyDialog {
    ::v-deep .el-dialog {
      width: 600px;

      .el-dialog__header {
        text-align: center;
        font-weight: bold;
      }
    }
    .content {
      .item {
        cursor: pointer;
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        .title {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 20px;

          .price {
            margin-left: 10px;
            color: red;
          }
        }

        .introduce {
          font-size: 15px;
        }

        .icon {
          position: absolute;
          right: 0;
          bottom: 0;

          img {
            width: 20px;
            display: block;
          }
        }
      }

      .blues {
        border: 1px solid #ff6900;
      }
    }

    .btn {
      margin-top: 20px;
      .btn_box {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .el-button {
          color: #ff6900;
          border: 1px solid #ff6900;

          &:nth-child(2) {
            color: #999999;
            border: 1px solid #999999;
          }
        }
      }

      .start_btn {
        display: flex;
        align-items: center;
        .el-button {
          padding: 15px;
          width: 100%;
          font-size: 16px;
        }
        .bian {
          height: 20px;
          width: 1px;
          background-color: #e8e8e8;
        }
      }
    }
  }
}
</style>
